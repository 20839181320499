import { Component } from '@angular/core';

@Component({
  selector: 'app-prayer-address',
  templateUrl: './prayer-address.component.html',
  styleUrls: ['./prayer-address.component.scss']
})
export class PrayerAddressComponent {

}
