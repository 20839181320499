import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		redirectTo: '',
		pathMatch: 'full',
	},
	{
        path: '',
        loadChildren: () => import('../app/modules/landing/landing.module').then(m => m.LandingModule)
    },
];

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule.forRoot(routes, {
			useHash: false,
			relativeLinkResolution: 'legacy',
		}),
	],
	exports: [],
})
export class AppRoutingModule {}
