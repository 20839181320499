import { Component} from '@angular/core';
import { initFlowbite } from 'flowbite';
import { ScrollService } from './shared/services/scroll.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent {

  constructor(
    private scrollService: ScrollService,
  ) { }

    ngOnInit(): void {
    initFlowbite();
  }

}
