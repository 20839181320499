import { Component } from '@angular/core';

@Component({
  selector: 'app-believe',
  templateUrl: './believe.component.html',
  styleUrls: ['./believe.component.scss']
})

export class BelieveComponent {

}
