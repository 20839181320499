import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatDialogModule} from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from "@angular/material/core";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { FooterComponent } from "./components/footer/footer.component";
import { RouterModule } from "@angular/router";
import { PrayerAddressComponent } from './components/prayer-address/prayer-address.component';
import { BelieveComponent } from './components/believe/believe.component';
import { ActivitiesComponent } from "./components/activities/activities.component";

const COMPS: Array<any> = [
  NavbarComponent,
  FooterComponent,
  PrayerAddressComponent,
  BelieveComponent,
  ActivitiesComponent,
];

@NgModule({
  declarations: [...COMPS],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MatTooltipModule,
    MatInputModule,
    MatSelectModule,
    NgxChartsModule,
    MatExpansionModule,
    MatIconModule,
    FlexLayoutModule,
    MatDialogModule,
    MatDatepickerModule,
    MatMenuModule,
    TranslateModule.forChild(),
    MatNativeDateModule,
    MatIconModule
  ],
  exports: [
    ...COMPS,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTooltipModule,
    MatSelectModule,
    NgxChartsModule,
    MatExpansionModule,
    MatDialogModule,
    FlexLayoutModule,
    MatMenuModule,
    TranslateModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule
  ],
  providers: [DatePipe]
})
export class SharedModule {}
